$skyblue: #f7fcff;
$green: #55d6be;
$blue: #2E5EAA;
$primary: #734ed2;
$text: #2b2a29;
$text-light: #7B7B7B;
$grid-gutter-width: 14px;
$input-btn-padding-y-lg: 10.5px;
$input-btn-font-size-lg: 18px;
$input-bg: #f1f3f8;
$input-border-color: $input-bg;
$input-placeholder-color: #9eaabd;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500,700&display=swap');

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

* {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
}

@import 'node_modules/bootstrap/scss/bootstrap-grid';

@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/carousel';

@import './fonts';

@import './typo';

@import './section';
@import './header';
@import './footer';
@import './jumbtron';
@import './servise-item';
@import './service-box';
@import './reviews';
@import './company';
@import './form-box';
@import './tabs-menu';
@import './tabs-preview';


* {
    box-sizing: border-box;
}

body {
    font-size: 14px;
    line-height: 26px;
    font-family: 'Montserrat', sans-serif;

    background: $skyblue;
    font-weight: 400;
}

.wrapper {
    min-width: 1140px;

    & .container-fluid {
        width: 1170px;
    }
}

button,
input,
select,
textarea {
    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

.btn-lg {
    padding: 17px 28px;

    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

.reviews {
    position: relative;

    width: 640px;
    height: 370px;
    margin: 138px auto 0;
    padding: 75px 78px 70px;

    z-index: 10;

    background: url('../img/review.png') no-repeat 50% 50%;

    &__item {
        font-size: 16px;
        line-height: 26px;

        color: $text-light;
    }

    & .carousel-inner {
        height: 185px;
    }

    &__next {
        position: absolute;
        top: 280px;
        right: 0;

        width: 38px;
        height: 18px;

        cursor: pointer;

        border: none;
        background: url('../img/arrow-right.svg') no-repeat 50% 50%;
    }

    &__prev {
        position: absolute;
        top: 283px;
        right: 55px;

        width: 24px;
        height: 12px;

        cursor: pointer;

        border: none;
        background: url('../img/arrow-left.svg') no-repeat 50% 50%;
    }
}

.form-box {
    &__title {
        margin-bottom: 30px;

        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }
    &__field {
        display: block;

        width: 100%;
        margin-bottom: 20px;
        padding: 18px;

        border: none;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 1px 10px rgba(0, 0, 0, .1);

        &:focus,
        &:active {
            box-shadow: 0 1px 10px rgba($green, .6) !important;
        }
    }

    &__textarea {
        display: block;

        width: 100%;
        height: 163px;
        margin: 10px 0 30px;
        padding: 18px;

        border: none;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 1px 10px rgba(0, 0, 0, .1);
        font-family: 'Montserrat', sans-serif;

        &:focus,
        &:active {
            box-shadow: 0 1px 10px rgba($green, .6) !important;
        }
    }
}

.header {
    padding-top: 30px;
    padding-bottom: 40px;

    &__logo {
        height: 48px;
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: center;

        list-style: none outside;

        font-size: 18px;
        line-height: 24px;

        color: $text;

        & a {
            text-decoration: none;

            color: initial;
        }

        & li {
            padding: 0 15px;
        }
    }

    &__order {
        padding: 11px 40px;

        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }
}

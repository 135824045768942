.service-box {
    margin: 40px 0;

    font-size: 16px;
    line-height: 22px;

    color: $text-light;

    &__title {
        margin-bottom: 30px;

        font-size: 24px;
        line-height: 29px;

        color: $text;

        &:before {
            content: '';
            display: block;
            width: 94px;
            height: 7px;
            margin-bottom: 35px;
            background-color: $primary;
        }
    }

    &:nth-of-type(2n) {
        & .service-box__title {
            &:before {
                content: '';
                display: block;
                width: 94px;
                height: 7px;
                margin-bottom: 35px;
                background-color: $green;
            }
        }
    }

    & p,
    & ul {
        margin: 30px 0;
    }

    & p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    & ul {
        padding-left: 20px;
    }
}

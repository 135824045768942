.tabs-preview {
    font-size: 16px;
    line-height: 22px;

    color: $text-light;

    display: none;

    &.active {
        display: block;
    }

    &__title {
        margin-bottom: 30px;

        color: $text;

        &:before {
            content: '';

            display: block;

            width: 94px;
            height: 7px;
            margin-bottom: 35px;

            background: $primary;
        }
    }

    & p {
        margin: 30px 0;
    }

    &__desc {
        width: 563px;
    }
}

.tabs-inner {
    display: none;

    &.active {
        display: block;
    }

    &__prices {
        width: 270px;
        height: 255px;
        padding: 30px 23px;

        border-top: 6px solid #55d6be;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0 1px 10px rgba(0, 0, 0, .1);
        margin-bottom: 30px;

        &__name {
            margin-bottom: 5px;

            font-weight: 700;
            font-size: 18px;
            line-height: 24px;

            color: #343434;
        }

        &__num {
            margin-bottom: 5px;

            font-size: 16px;
            line-height: 26px;

            color: #68717a;
        }

        &__place {
            margin-bottom: 35px;

            font-size: 16px;
            line-height: 19px;

            color: #343434;
        }

        &__btn {
            & .btn {
                width: 100%;
                padding: 17px 20px;

                font-weight: 700;
                font-size: 16px;
                line-height: 19px;

                text-transform: uppercase;

                background: #5b4e77;
            }
        }
    }
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Bold.eot');
    src: local('Futura PT Bold'), local('FuturaPT-Bold'),
        url('../fonts/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-Bold.woff2') format('woff2'),
        url('../fonts/FuturaPT-Bold.woff') format('woff'),
        url('../fonts/FuturaPT-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Heavy.eot');
    src: local('Futura PT Heavy'), local('FuturaPT-Heavy'),
        url('../fonts/FuturaPT-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-Heavy.woff2') format('woff2'),
        url('../fonts/FuturaPT-Heavy.woff') format('woff'),
        url('../fonts/FuturaPT-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Demi';
    src: url('../fonts/FuturaPT-DemiObl.eot');
    src: local('Futura PT Demi Oblique'), local('FuturaPT-DemiObl'),
        url('../fonts/FuturaPT-DemiObl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-DemiObl.woff2') format('woff2'),
        url('../fonts/FuturaPT-DemiObl.woff') format('woff'),
        url('../fonts/FuturaPT-DemiObl.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url('../fonts/FuturaPTCond-ExtraBoldObl.eot');
    src: local('Futura PT Cond Extra Bold Oblique'), local('FuturaPTCond-ExtraBoldObl'),
        url('../fonts/FuturaPTCond-ExtraBoldObl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPTCond-ExtraBoldObl.woff2') format('woff2'),
        url('../fonts/FuturaPTCond-ExtraBoldObl.woff') format('woff'),
        url('../fonts/FuturaPTCond-ExtraBoldObl.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url('../fonts/FuturaPTCond-Book.eot');
    src: local('Futura PT Cond Book'), local('FuturaPTCond-Book'),
        url('../fonts/FuturaPTCond-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPTCond-Book.woff2') format('woff2'),
        url('../fonts/FuturaPTCond-Book.woff') format('woff'),
        url('../fonts/FuturaPTCond-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-LightObl.eot');
    src: local('Futura PT Light Oblique'), local('FuturaPT-LightObl'),
        url('../fonts/FuturaPT-LightObl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-LightObl.woff2') format('woff2'),
        url('../fonts/FuturaPT-LightObl.woff') format('woff'),
        url('../fonts/FuturaPT-LightObl.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('../fonts/FuturaPT-BookObl.eot');
    src: local('Futura PT Book Oblique'), local('FuturaPT-BookObl'),
        url('../fonts/FuturaPT-BookObl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-BookObl.woff2') format('woff2'),
        url('../fonts/FuturaPT-BookObl.woff') format('woff'),
        url('../fonts/FuturaPT-BookObl.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-HeavyObl.eot');
    src: local('Futura PT Heavy Oblique'), local('FuturaPT-HeavyObl'),
        url('../fonts/FuturaPT-HeavyObl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-HeavyObl.woff2') format('woff2'),
        url('../fonts/FuturaPT-HeavyObl.woff') format('woff'),
        url('../fonts/FuturaPT-HeavyObl.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('../fonts/FuturaPTCond-BoldObl.eot');
    src: local('Futura PT Cond Bold Oblique'), local('FuturaPTCond-BoldObl'),
        url('../fonts/FuturaPTCond-BoldObl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPTCond-BoldObl.woff2') format('woff2'),
        url('../fonts/FuturaPTCond-BoldObl.woff') format('woff'),
        url('../fonts/FuturaPTCond-BoldObl.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Demi';
    src: url('../fonts/FuturaPT-Demi.eot');
    src: local('Futura PT Demi'), local('FuturaPT-Demi'),
        url('../fonts/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-Demi.woff2') format('woff2'),
        url('../fonts/FuturaPT-Demi.woff') format('woff'),
        url('../fonts/FuturaPT-Demi.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url('../fonts/FuturaPTCond-BookObl.eot');
    src: local('Futura PT Cond Book Oblique'), local('FuturaPTCond-BookObl'),
        url('../fonts/FuturaPTCond-BookObl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPTCond-BookObl.woff2') format('woff2'),
        url('../fonts/FuturaPTCond-BookObl.woff') format('woff'),
        url('../fonts/FuturaPTCond-BookObl.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Extra';
    src: url('../fonts/FuturaPT-ExtraBold.eot');
    src: local('Futura PT Extra Bold'), local('FuturaPT-ExtraBold'),
        url('../fonts/FuturaPT-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-ExtraBold.woff2') format('woff2'),
        url('../fonts/FuturaPT-ExtraBold.woff') format('woff'),
        url('../fonts/FuturaPT-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('../fonts/FuturaPTCond-Medium.eot');
    src: local('Futura PT Cond Medium'), local('FuturaPTCond-Medium'),
        url('../fonts/FuturaPTCond-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPTCond-Medium.woff2') format('woff2'),
        url('../fonts/FuturaPTCond-Medium.woff') format('woff'),
        url('../fonts/FuturaPTCond-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Medium.eot');
    src: local('Futura PT Medium'), local('FuturaPT-Medium'),
        url('../fonts/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-Medium.woff2') format('woff2'),
        url('../fonts/FuturaPT-Medium.woff') format('woff'),
        url('../fonts/FuturaPT-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url('../fonts/FuturaPTCond-ExtraBold.eot');
    src: local('Futura PT Cond Extra Bold'), local('FuturaPTCond-ExtraBold'),
        url('../fonts/FuturaPTCond-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPTCond-ExtraBold.woff2') format('woff2'),
        url('../fonts/FuturaPTCond-ExtraBold.woff') format('woff'),
        url('../fonts/FuturaPTCond-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-MediumObl.eot');
    src: local('Futura PT Medium Oblique'), local('FuturaPT-MediumObl'),
        url('../fonts/FuturaPT-MediumObl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-MediumObl.woff2') format('woff2'),
        url('../fonts/FuturaPT-MediumObl.woff') format('woff'),
        url('../fonts/FuturaPT-MediumObl.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('../fonts/FuturaPTCond-Bold.eot');
    src: local('Futura PT Cond Bold'), local('FuturaPTCond-Bold'),
        url('../fonts/FuturaPTCond-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPTCond-Bold.woff2') format('woff2'),
        url('../fonts/FuturaPTCond-Bold.woff') format('woff'),
        url('../fonts/FuturaPTCond-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-BoldObl.eot');
    src: local('Futura PT Bold Oblique'), local('FuturaPT-BoldObl'),
        url('../fonts/FuturaPT-BoldObl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-BoldObl.woff2') format('woff2'),
        url('../fonts/FuturaPT-BoldObl.woff') format('woff'),
        url('../fonts/FuturaPT-BoldObl.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('../fonts/FuturaPT-Book.eot');
    src: local('Futura PT Book'), local('FuturaPT-Book'),
        url('../fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-Book.woff2') format('woff2'),
        url('../fonts/FuturaPT-Book.woff') format('woff'),
        url('../fonts/FuturaPT-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Light.eot');
    src: local('Futura PT Light'), local('FuturaPT-Light'),
        url('../fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-Light.woff2') format('woff2'),
        url('../fonts/FuturaPT-Light.woff') format('woff'),
        url('../fonts/FuturaPT-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('../fonts/FuturaPTCond-MediumObl.eot');
    src: local('Futura PT Cond Medium Oblique'), local('FuturaPTCond-MediumObl'),
        url('../fonts/FuturaPTCond-MediumObl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPTCond-MediumObl.woff2') format('woff2'),
        url('../fonts/FuturaPTCond-MediumObl.woff') format('woff'),
        url('../fonts/FuturaPTCond-MediumObl.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Extra';
    src: url('../fonts/FuturaPT-ExtraBoldObl.eot');
    src: local('Futura PT Extra Bold Oblique'), local('FuturaPT-ExtraBoldObl'),
        url('../fonts/FuturaPT-ExtraBoldObl.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaPT-ExtraBoldObl.woff2') format('woff2'),
        url('../fonts/FuturaPT-ExtraBoldObl.woff') format('woff'),
        url('../fonts/FuturaPT-ExtraBoldObl.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Bold.eot');
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
        url('../fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-Bold.woff2') format('woff2'),
        url('../fonts/SourceSansPro-Bold.woff') format('woff'),
        url('../fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-ExtraLightItalic.eot');
    src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'),
        url('../fonts/SourceSansPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-ExtraLightItalic.woff') format('woff'),
        url('../fonts/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Light.eot');
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
        url('../fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-Light.woff2') format('woff2'),
        url('../fonts/SourceSansPro-Light.woff') format('woff'),
        url('../fonts/SourceSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-BoldItalic.eot');
    src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
        url('../fonts/SourceSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-BoldItalic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-BoldItalic.woff') format('woff'),
        url('../fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-LightItalic.eot');
    src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
        url('../fonts/SourceSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-LightItalic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-LightItalic.woff') format('woff'),
        url('../fonts/SourceSansPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Black.eot');
    src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
        url('../fonts/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-Black.woff2') format('woff2'),
        url('../fonts/SourceSansPro-Black.woff') format('woff'),
        url('../fonts/SourceSansPro-Black.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Regular.eot');
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
        url('../fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-Regular.woff2') format('woff2'),
        url('../fonts/SourceSansPro-Regular.woff') format('woff'),
        url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-SemiBold.eot');
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
        url('../fonts/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
        url('../fonts/SourceSansPro-SemiBold.woff') format('woff'),
        url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Italic.eot');
    src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
        url('../fonts/SourceSansPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-Italic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-Italic.woff') format('woff'),
        url('../fonts/SourceSansPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-SemiBoldItalic.eot');
    src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'),
        url('../fonts/SourceSansPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-SemiBoldItalic.woff') format('woff'),
        url('../fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-BlackItalic.eot');
    src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'),
        url('../fonts/SourceSansPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-BlackItalic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-BlackItalic.woff') format('woff'),
        url('../fonts/SourceSansPro-BlackItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-ExtraLight.eot');
    src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
        url('../fonts/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
        url('../fonts/SourceSansPro-ExtraLight.woff') format('woff'),
        url('../fonts/SourceSansPro-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}



@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Medium.eot');
    src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
        url('../fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-Medium.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Medium.woff') format('woff'),
        url('../fonts/SFProDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Heavy.eot');
    src: local('SF Pro Display Heavy'), local('SFProDisplay-Heavy'),
        url('../fonts/SFProDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-Heavy.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Heavy.woff') format('woff'),
        url('../fonts/SFProDisplay-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-UltralightItalic.eot');
    src: local('SF Pro Display UltralightItalic'), local('SFProDisplay-UltralightItalic'),
        url('../fonts/SFProDisplay-UltralightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-UltralightItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-UltralightItalic.woff') format('woff'),
        url('../fonts/SFProDisplay-UltralightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-RegularItalic.eot');
    src: local('SF Pro Display Regular Italic'), local('SFProDisplay-RegularItalic'),
        url('../fonts/SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-RegularItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-RegularItalic.woff') format('woff'),
        url('../fonts/SFProDisplay-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Thin.eot');
    src: local('SF Pro Display Thin'), local('SFProDisplay-Thin'),
        url('../fonts/SFProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-Thin.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Thin.woff') format('woff'),
        url('../fonts/SFProDisplay-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Light.eot');
    src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
        url('../fonts/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-Light.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Light.woff') format('woff'),
        url('../fonts/SFProDisplay-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-BlackItalic.eot');
    src: local('SF Pro Display Black Italic'), local('SFProDisplay-BlackItalic'),
        url('../fonts/SFProDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-BlackItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-BlackItalic.woff') format('woff'),
        url('../fonts/SFProDisplay-BlackItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Bold.eot');
    src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
        url('../fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-Bold.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Bold.woff') format('woff'),
        url('../fonts/SFProDisplay-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Black.eot');
    src: local('SF Pro Display Black'), local('SFProDisplay-Black'),
        url('../fonts/SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-Black.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Black.woff') format('woff'),
        url('../fonts/SFProDisplay-Black.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-SemiboldItalic.eot');
    src: local('SF Pro Display SemiboldItalic'), local('SFProDisplay-SemiboldItalic'),
        url('../fonts/SFProDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-SemiboldItalic.woff') format('woff'),
        url('../fonts/SFProDisplay-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Ultralight.eot');
    src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
        url('../fonts/SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-Ultralight.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Ultralight.woff') format('woff'),
        url('../fonts/SFProDisplay-Ultralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-LightItalic.eot');
    src: local('SF Pro Display LightItalic'), local('SFProDisplay-LightItalic'),
        url('../fonts/SFProDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-LightItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-LightItalic.woff') format('woff'),
        url('../fonts/SFProDisplay-LightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-ThinItalic.eot');
    src: local('SF Pro Display ThinItalic'), local('SFProDisplay-ThinItalic'),
        url('../fonts/SFProDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-ThinItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-ThinItalic.woff') format('woff'),
        url('../fonts/SFProDisplay-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-MediumItalic.eot');
    src: local('SF Pro Display MediumItalic'), local('SFProDisplay-MediumItalic'),
        url('../fonts/SFProDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-MediumItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-MediumItalic.woff') format('woff'),
        url('../fonts/SFProDisplay-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Semibold.eot');
    src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
        url('../fonts/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-Semibold.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Semibold.woff') format('woff'),
        url('../fonts/SFProDisplay-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-HeavyItalic.eot');
    src: local('SF Pro Display HeavyItalic'), local('SFProDisplay-HeavyItalic'),
        url('../fonts/SFProDisplay-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-HeavyItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-HeavyItalic.woff') format('woff'),
        url('../fonts/SFProDisplay-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Regular.eot');
    src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
        url('../fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Regular.woff') format('woff'),
        url('../fonts/SFProDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-BoldItalic.eot');
    src: local('SF Pro Display BoldItalic'), local('SFProDisplay-BoldItalic'),
        url('../fonts/SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-BoldItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-BoldItalic.woff') format('woff'),
        url('../fonts/SFProDisplay-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

.footer {
    padding: 52px 0 63px;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    letter-spacing: .2px;

    color: #fff;
    background-color: $green;

    &__title {
        margin-bottom: 30px;

        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }

    &__phone {
        margin-bottom: 16px;

        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }

    &__worktime {
        width: 247px;
        margin-bottom: 16px;

        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }

    &__email {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }

    &__desc {
        width: 548px;
    }

    &__social {
        display: flex;
        margin-top: 65px;

        & a {
            display: block;
            margin-right: 10px;
        }
    }
}

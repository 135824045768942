.tabs-menu {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 70px;

    & ul {
        display: flex;
        justify-content: center;
        list-style: none outside;

        & li {
            padding-left: 10px;
            padding-right: 10px;

            & a {
                color: $text;

                &.active {
                    font-weight: 700;
                }
            }
        }
    }
}

h1,
.h1 {
    font-family: 'Montserrat', sans-serif;
}

h2,
.h2 {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 30px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

h3,
.h3 {
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 30px;
}

h4,
.h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 23px;
    }
}

p {
    margin-bottom: 20px;
}

.servise-item {
    width: 362px;
    height: 276px;
    padding: 35px 40px;

    font-size: 14px;
    line-height: 20px;

    color: $text-light;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .1);

    & span {
        color: $blue;
    }

    &__icon {
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 20px;

        font-weight: 700;
        font-size: 24px;
        line-height: 32px;

        color: $text;

        &:after {
            content: '';

            display: block;

            width: 50px;
            height: 2px;
            margin-top: 20px;

            background: $primary;
        }
    }
}

.company {
    &-list {
        display: flex;
        justify-content: space-between;
        padding-left: 45px;
        padding-right: 45px;
        padding-bottom: 40px;
        padding-top: 40px;
        &__item {
            width: 238px;
            height: 341px;

            border-radius: 20px;
            background: #fff;
            box-shadow: 0 13px 19px rgba(0, 0, 0, .07);

            &__avatar {
                & img {
                    display: block;
                }
            }

            &__name {
                margin: 30px 20px 14px;

                font-weight: 700;
                font-size: 16px;
                line-height: 20px;

                text-align: center;
                color: $green;
            }

            &__position {
                margin: 0 27px;

                font-size: 12px;
                line-height: 15px;

                text-align: center;

                color: $text-light;
            }
        }

        &__next {
            position: absolute;
            top: 50%;
            right: -10px;
    
            width: 38px;
            height: 18px;
    
            cursor: pointer;
    
            border: none;
            background: url('../img/arrow-right.svg') no-repeat 50% 50%;
        }
    
        &__prev {
            position: absolute;
            top: 50%;
            left: -10px;
    
            width: 38px;
            height: 18px;
    
            cursor: pointer;
    
            border: none;
            background: url('../img/arrow-right.svg') no-repeat 50% 50%;
            transform: rotate(-180deg);
        }
    }
}

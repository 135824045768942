.jumbtron {
    width: 694px;
    margin-bottom: 109px;

    &__pretitle {
        margin-bottom: 20px;

        font-weight: 700;
        font-size: 14px;
        line-height: 17px;

        color: $primary;
    }

    &__title {
        margin-bottom: 20px;

        font-size: 64px;
        line-height: 78px;

        color: $text;
    }

    &__desc {
        margin-bottom: 30px;

        font-size: 16px;
        line-height: 22px;

        color: $text-light;

        & span {
            color: $blue;
        }
    }
}
